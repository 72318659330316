import { InteractionMap, ProjectInteraction } from '../types';
import { isTriggerGlobal } from './is-trigger-global';

export function getInteractionMap(interactions: ProjectInteraction[] = []): InteractionMap {
  const result: InteractionMap = {
    trigger: {
      global: {
        onScroll: [],
        onTimer: [],
        onKeyDown: [],
        onKeyUp: [],
      },
      widget: {},
    },
    event: {
      widget: {},
    },
  };

  interactions.forEach((interaction) => {
    if (interaction.trigger?.type && isTriggerGlobal(interaction.trigger?.type)) {
      const type = interaction.trigger?.type as keyof InteractionMap['trigger']['global'];
      const globalElement = result.trigger.global[type];
      globalElement.push(interaction);
    } else {
      createOrUpdateArray(result.trigger.widget, interaction.trigger?.contentKey, interaction);
    }

    createOrUpdateArray(result.event.widget, interaction.event?.contentKey, interaction);
  });

  return result;
}

function createOrUpdateArray(
  map: Record<string, ProjectInteraction[]>,
  key: string | undefined,
  interaction: ProjectInteraction,
) {
  if (!key) return;
  if (!Array.isArray(map[key])) {
    map[key] = [];
  }

  map[key].push(interaction);
}
