// Triggers
export enum DOM_TRIGGER_TYPES {
  ON_CLICK = 'onClick',
  ON_MOUSE_ENTER = 'onMouseEnter',
  ON_MOUSE_LEAVE = 'onMouseLeave',
  ON_MOUSE_DOWN = 'onMouseDown',
  ON_MOUSE_UP = 'onMouseUp',
  ON_DOUBLE_CLICK = 'onDoubleClick',
  ON_TOUCH_START = 'onTouchStart',
  ON_TOUCH_END = 'onTouchEnd',
  ON_ANIMATION_START = 'onAnimationStart',
  ON_ANIMATION_END = 'onAnimationEnd',
}

export enum CUSTOM_TRIGGER_TYPES {
  ON_VISIBLE = 'onVisible',
  ON_LEAVE = 'onLeave',
  ON_SWIPE = 'onSwipe',
}

export enum GLOBAL_TRIGGER_TYPES {
  ON_SCROLL = 'onScroll',
  ON_TIMER = 'onTimer',
  ON_KEY_DOWN = 'onKeyDown',
  ON_KEY_UP = 'onKeyUp',
}

// Events
export enum GLOBAL_EVENT_TYPES {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  STICK = 'STICK',
  UNSTICK = 'UNSTICK',
  TOGGLE = 'TOGGLE_VISIBILITY',
  SCROLL_TO_ELEMENT = 'SCROLL_TO_ELEMENT',
  OPEN_PAGE = 'OPEN_PAGE',
  OPEN_URL = 'OPEN_URL',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  SEND_EMAIL = 'SEND_EMAIL',
  CALL_PHONE = 'CALL_PHONE',
  SEND_SMS = 'SEND_SMS',
  SET_VARIABLE = 'SET_VARIABLE',
  RESET_VARIABLE = 'RESET_VARIABLE',
  TRACK = 'TRACK',
  SHARE = 'SHARE',
  ANIMATE = 'ANIMATE',
  CHANGE_VARIANT = 'CHANGE_VARIANT',
  TOGGLE_VARIANT = 'TOGGLE_VARIANT',
}

// Scopes
export enum TRIGGER_SCOPE {
  POINTER_EVENT = 'pointer-event',
  SCROLL = 'scroll',
  GLOBAL = 'global',
  KEYBOARD = 'keyboard',
}

export enum EVENT_SCOPE {
  ELEMENT = 'element',
  INSTANCE = 'instance',
  PAGE = 'page',
  EXTERNAL = 'external',
  VARIABLE = 'variable',
  TRACKING = 'tracking',
}

// Types
export type VevDispatchEvent = {
  type?: string;
  contentKey?: string;
  instanceKeyChains?: string[];
  componentKey?: string;
  args?: Record<string, string>;
  nodeId?: string;
};

export type VevTriggerType = DOM_TRIGGER_TYPES | CUSTOM_TRIGGER_TYPES | string;

export type VevCondition = string;

export type VevTrigger = {
  type: VevTriggerType;
  contentKey?: string;
  name?: string;
  condition?: VevCondition;
  args?: Record<string, string>;
};

export type ProjectInteraction = {
  key: string;
  event?: VevDispatchEvent;
  trigger?: VevTrigger;
  disabled?: boolean;
  updated: number;
  deleted?: number;
};

export type InteractionMap = {
  trigger: {
    global: Record<string, ProjectInteraction[]>;
    widget: Record<string, ProjectInteraction[]>;
  };
  event: {
    widget: Record<string, ProjectInteraction[]>;
  };
};

// UI Types
export type TriggerItem = {
  label: string;
  value: VevTriggerType;
  icon?: string;
  scope?: EVENT_SCOPE | TRIGGER_SCOPE;
  suggested?: boolean;
};

export type EventBrokerCallback = (args?: Record<string, any>) => void;
export type EventBrokerCallbackRecord = Map<string, EventBrokerCallback>;
export type InteractionsManagerArgs = {
  config?: { disabled?: boolean; debug?: boolean };
};

export type InteractionsNodeArgs = {
  id: string;
  rootNode: HTMLElement;
  interactions: InteractionMap;
  animations?: AnimationKeyframesMap;
  config: { disabled?: boolean; debug?: boolean; legacy?: boolean };
};

export type AnimationKeyframesMap = {
  [key: string]: {
    keyframes: Keyframe[];
    type: 'in' | 'out' | 'highlight';
  };
};

export type AnimationArgs = {
  animation: string;
  duration: string;
  delay: string;
  easing: string;
  loop?: boolean;
  alternate?: boolean;
  scroll?: boolean;
  'animation-range-start'?: number;
  'animation-range-end'?: number;
  [key: `--${string}`]: string;
};
