import {
  CUSTOM_TRIGGER_TYPES,
  DOM_TRIGGER_TYPES,
  EVENT_SCOPE,
  GLOBAL_EVENT_TYPES,
  GLOBAL_TRIGGER_TYPES,
  TRIGGER_SCOPE,
  TriggerItem,
} from './types';

export const NATIVE_TRIGGERS: TriggerItem[] = [
  {
    label: 'On click',
    value: DOM_TRIGGER_TYPES.ON_CLICK,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse down',
    value: DOM_TRIGGER_TYPES.ON_MOUSE_DOWN,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse up',
    value: DOM_TRIGGER_TYPES.ON_MOUSE_UP,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse enter',
    value: DOM_TRIGGER_TYPES.ON_MOUSE_ENTER,
    icon: 'interaction.mouse-enter',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On mouse leave',
    value: DOM_TRIGGER_TYPES.ON_MOUSE_LEAVE,
    icon: 'interaction.mouse-leave',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On double click',
    value: DOM_TRIGGER_TYPES.ON_DOUBLE_CLICK,
    icon: 'interaction.double-click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On touch start',
    value: DOM_TRIGGER_TYPES.ON_TOUCH_START,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
  {
    label: 'On touch end',
    value: DOM_TRIGGER_TYPES.ON_TOUCH_END,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
];

export const CUSTOM_TRIGGERS: TriggerItem[] = [
  {
    label: 'Upon entering view',
    value: CUSTOM_TRIGGER_TYPES.ON_VISIBLE,
    icon: 'eye',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'Upon leaving view',
    value: CUSTOM_TRIGGER_TYPES.ON_LEAVE,
    icon: 'eye.off',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'On swipe',
    value: CUSTOM_TRIGGER_TYPES.ON_SWIPE,
    icon: 'interaction.click',
    scope: TRIGGER_SCOPE.POINTER_EVENT,
  },
];

export const ANIMATION_TRIGGERS: TriggerItem[] = [
  {
    label: 'On animation start',
    value: DOM_TRIGGER_TYPES.ON_ANIMATION_START,
    icon: 'play',
    scope: TRIGGER_SCOPE.GLOBAL,
  },
  {
    label: 'On animation end',
    value: DOM_TRIGGER_TYPES.ON_ANIMATION_END,
    icon: 'play',
    scope: TRIGGER_SCOPE.GLOBAL,
  },
];

export const GLOBAL_TRIGGERS: TriggerItem[] = [
  {
    label: 'On scroll',
    value: GLOBAL_TRIGGER_TYPES.ON_SCROLL,
    icon: 'interaction.mouse',
    scope: TRIGGER_SCOPE.SCROLL,
  },
  {
    label: 'On timer',
    value: GLOBAL_TRIGGER_TYPES.ON_TIMER,
    icon: 'interaction.timer',
    scope: TRIGGER_SCOPE.GLOBAL,
  },
  {
    label: 'On key down (key pressed)',
    value: GLOBAL_TRIGGER_TYPES.ON_KEY_DOWN,
    icon: 'keyboard.command',
    scope: TRIGGER_SCOPE.KEYBOARD,
  },
  {
    label: 'On key up (key released)',
    value: GLOBAL_TRIGGER_TYPES.ON_KEY_UP,
    icon: 'keyboard.command',
    scope: TRIGGER_SCOPE.KEYBOARD,
  },
];

export const GLOBAL_EVENTS: TriggerItem[] = [
  {
    label: 'Animate',
    value: GLOBAL_EVENT_TYPES.ANIMATE,
    scope: EVENT_SCOPE.ELEMENT,
    suggested: true,
  },
  { label: 'Show', value: GLOBAL_EVENT_TYPES.SHOW, scope: EVENT_SCOPE.ELEMENT, suggested: true },
  { label: 'Hide', value: GLOBAL_EVENT_TYPES.HIDE, scope: EVENT_SCOPE.ELEMENT, suggested: true },
  { label: 'Stick', value: GLOBAL_EVENT_TYPES.STICK, scope: EVENT_SCOPE.ELEMENT },
  {
    label: 'Unstick',
    value: GLOBAL_EVENT_TYPES.UNSTICK,
    scope: EVENT_SCOPE.ELEMENT,
  },
  {
    label: 'Toggle visibility',
    value: GLOBAL_EVENT_TYPES.TOGGLE,
    scope: EVENT_SCOPE.ELEMENT,
    suggested: true,
  },
  {
    label: 'Change variant',
    value: GLOBAL_EVENT_TYPES.CHANGE_VARIANT,
    scope: EVENT_SCOPE.INSTANCE,
    suggested: true,
  },
  {
    label: 'Toggle variant',
    value: GLOBAL_EVENT_TYPES.TOGGLE_VARIANT,
    scope: EVENT_SCOPE.INSTANCE,
    suggested: true,
  },
  {
    label: 'Scroll to element',
    value: GLOBAL_EVENT_TYPES.SCROLL_TO_ELEMENT,
    scope: EVENT_SCOPE.ELEMENT,
    suggested: true,
  },
  {
    label: 'Open page',
    value: GLOBAL_EVENT_TYPES.OPEN_PAGE,
    icon: 'link',
    scope: EVENT_SCOPE.PAGE,
    suggested: true,
  },
  {
    label: 'Go to URL',
    value: GLOBAL_EVENT_TYPES.OPEN_URL,
    icon: 'link.external',
    scope: EVENT_SCOPE.EXTERNAL,
    suggested: true,
  },
  {
    label: 'Send email',
    value: GLOBAL_EVENT_TYPES.SEND_EMAIL,
    icon: 'email',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Call phone number',
    value: GLOBAL_EVENT_TYPES.CALL_PHONE,
    icon: 'mobile',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Download file',
    value: GLOBAL_EVENT_TYPES.DOWNLOAD_FILE,
    icon: 'file',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Send SMS',
    value: GLOBAL_EVENT_TYPES.SEND_SMS,
    icon: 'comment',
    scope: EVENT_SCOPE.EXTERNAL,
  },
  {
    label: 'Track event',
    value: GLOBAL_EVENT_TYPES.TRACK,
    icon: 'trackingEvent',
    scope: EVENT_SCOPE.TRACKING,
    suggested: true,
  },
  {
    label: 'Set variable',
    value: GLOBAL_EVENT_TYPES.SET_VARIABLE,
    icon: 'variable.icon',
    scope: EVENT_SCOPE.VARIABLE,
    suggested: true,
  },
  {
    label: 'Reset variable',
    value: GLOBAL_EVENT_TYPES.RESET_VARIABLE,
    icon: 'undo',
    scope: EVENT_SCOPE.VARIABLE,
  },
  {
    label: 'Share',
    value: GLOBAL_EVENT_TYPES.SHARE,
    icon: 'share',
    scope: EVENT_SCOPE.EXTERNAL,
  },
];
