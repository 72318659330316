import {
  GlobalTimer,
  attachGlobalListeners,
  convertToDomEventType,
  dispatchExternalTrackingEvent,
  getOuterNodeId,
  isNativeEvent,
  isShareEvent,
  isTargetVariable,
  isTrackingEvent,
  isTriggerGlobal,
  triggerShareDialog,
  evaluateEventCondition,
} from './utils';
import {
  DOM_TRIGGER_TYPES,
  VevDispatchEvent,
  VevTriggerType,
  InteractionMap,
  ProjectInteraction,
  InteractionsManagerArgs,
  EventBrokerCallbackRecord,
  EventBrokerCallback,
} from './types';
import { VevInteractionsNode } from './interactions-node';

declare global {
  interface Window {
    vevInteractionsManager?: VevInteractionsManager;
  }
}

//                     ▄░▀▀▀▀░▄
//              ██    █░██▀██░█▄    Jasså?!?
//               █      ▀▄ ▀ ▄▀
//               █▄▄▄▄   ████

const logger = (...data: any[]) => console.log('🔵 InteractionsManager -> ', ...data);

/**
 * initInteractionsManager
 *
 * @param debug         boolean         - Enable/disable logging
 * @param disabled      boolean         - Disable actions (in editor mode)
 * @returns destroy()   function        - Function to destroy the manager
 */
export function initInteractionsManager(args: InteractionsManagerArgs): VevInteractionsManager {
  if (typeof window.vevInteractionsManager !== 'undefined') {
    window.vevInteractionsManager.update(args);
    return window.vevInteractionsManager;
  }

  const manager = new VevInteractionsManager(args);

  // Attach to window and expose to contexts
  if (window) (window as any).vevInteractionsManager = manager;
  if (document) document.documentElement.setAttribute('data-vev-interactions', 'true');

  return manager;
}

/**
 * VevInteractionsManager Class
 *
 * -> Keep track of all interactions on the page
 * -> Listen for VEV events
 * -> Trigger callbacks
 */
export class VevInteractionsManager {
  private interactionsMap: Map<string, InteractionMap>;
  private records: EventBrokerCallbackRecord;
  private nodes: Map<string, VevInteractionsNode>;
  private debug: (...data: any[]) => void;
  private disabled: boolean;
  private timer: GlobalTimer;

  constructor(args: InteractionsManagerArgs) {
    this.interactionsMap = new Map();
    this.timer = new GlobalTimer();
    this.records = new Map();
    this.nodes = new Map();
    this.debug = args?.config?.debug ? logger : () => {};
    this.disabled = args?.config?.disabled || false;

    this.debug(`Initialised${this.disabled ? ' (Disabled)' : ''}`);

    if (window) {
      (window as any).addEventListener('@@vev', this.handleEvent as EventListener);
      this.reloadTimer();
      this.attachGlobalTriggers();
      this.attachVariableListeners();
    }
  }

  private attachGlobalTriggers = () => {
    attachGlobalListeners();
  };

  public update = (args: InteractionsManagerArgs) => {
    this.debug('Updating');
    this.disabled = args?.config?.disabled || false;
    this.reloadTimer();
  };

  private reloadTimer = () => {
    this.timer.stopTimer();
    this.timer.clearTimeslots();
    // Find all project interactions with a timer trigger
    for (const interactions of this.interactionsMap.values()) {
      const timerInteractions = interactions?.trigger?.global?.onTimer || [];
      // Register all timer interactions
      timerInteractions.forEach((interaction) => {
        if (interaction?.deleted) return;
        const conditions = interaction.trigger?.condition?.split(' OR ');
        for (const condition of conditions || []) {
          const delayValue = condition?.split('=')[1];
          const delay = delayValue ? parseInt(delayValue) : 1000;
          const runOnce = condition.includes('timeout');

          this.timer.registerForTimeslot(
            interaction.key + delay,
            delay,
            () => {
              const callbackName = `${interaction?.event?.type}.${interaction?.event?.contentKey}`;
              const callback = this.records.get(callbackName);

              const isDisabled = interaction?.disabled;

              if (callback && !isDisabled) {
                callback({
                  ...interaction.event?.args,
                  interactionKey: interaction.key,
                  interaction,
                });
              }
            },
            !!runOnce,
          );
        }
      });
    }

    if (!this.disabled) this.timer.startTimer();
  };

  // Register all variable interactions
  private attachVariableListeners = () => {
    for (const [id, interactions] of this.interactionsMap.entries()) {
      // Find all project interactions with a variable event
      Object.keys(interactions?.event.widget).forEach((key) => {
        const widgetInteractions = interactions?.event.widget[key];
        widgetInteractions.forEach((interaction) => {
          if (isTargetVariable(interaction?.event?.type)) {
            if (interaction?.event?.contentKey) {
              const callbackName = `${interaction.event.type}.${interaction.event.contentKey}`;
              this.records.set(callbackName, (args) => {
                const actualArgs = args || interaction.event?.args;
                if (interaction.event && (window as any)?.variable) {
                  (window as any).variable.setVariable(interaction.event.contentKey, {
                    value: actualArgs?.value,
                  });
                }
              });
            }
          }
        });
      });
    }
  };

  /**
   * handleEvent - Receive global VEV events and trigger relevant callbacks
   * @param event CustomEvent<VevDispatchEvent>
   */
  private handleEvent = (event: CustomEvent<VevDispatchEvent>) => {
    if (this.disabled) {
      this.debug('Disabled - Ignoring event');
      return;
    }

    this.debug('→ Received Event', event.detail);

    // Array of relevant interactions
    const matches: ProjectInteraction[] = [];
    const nodeInteractions = this.interactionsMap.get(event.detail?.nodeId || '');

    // If the event has a contentKey, add all interactions for that contentKey
    if (event.detail.contentKey) {
      matches.push(...(nodeInteractions?.trigger?.widget?.[event.detail.contentKey] || []));
    }

    // main component interactions
    if (event.detail.instanceKeyChains) {
      for (const instanceKeyChain of event.detail.instanceKeyChains) {
        const contentKeyChain = `${event.detail.contentKey}${instanceKeyChain || ''}`;
        matches.push(...(nodeInteractions?.trigger?.widget?.[contentKeyChain] || []));
      }
    }
    // If the event has a componentKey, add all interactions for that componentKey
    if (event.detail.componentKey) {
      matches.push(...(nodeInteractions?.trigger?.widget?.[event.detail.componentKey] || []));
    }

    // If the event is a global trigger, add interactions for that global trigger type
    if (event.detail?.type && isTriggerGlobal(event.detail.type)) {
      matches.push(...(nodeInteractions?.trigger?.global?.[event.detail.type] || []));
    }

    // Loop through matches and fire callback if condition is met
    matches
      .filter((i) => {
        if (isNativeEvent(i.trigger?.type || '')) {
          return event.detail.type === convertToDomEventType(i.trigger?.type as DOM_TRIGGER_TYPES);
        }
        return event.detail.type === i.trigger?.type;
      })
      .forEach((interaction) => {
        const contentKey = interaction?.event?.contentKey;
        const chains = event.detail.instanceKeyChains;
        const callbackName = `${interaction?.event?.type}.${contentKey}`;
        const callbacks: EventBrokerCallback[] = [];
        const isDisabled = interaction?.disabled;

        // Check if a callback exists with the key provided
        const callback = this.records.get(callbackName);
        if (callback) callbacks.push(callback);
        // Check if the event is meant for a main component
        if (chains) {
          for (const chain of chains) {
            // Check if a callback exists with the keyChain provided
            const chainCallback = this.records.get(callbackName + chain);
            if (chainCallback) callbacks.push(chainCallback);
          }
        }

        if (callbacks.length && !isDisabled) {
          for (const callback of callbacks) {
            const args = {
              ...(interaction.event?.args || event.detail.args),
              interactionKey: interaction.key,
            };
            if (interaction?.trigger?.condition) {
              const success = evaluateEventCondition(
                interaction?.trigger?.condition,
                event.detail.args,
              );
              if (success) {
                this.debug('➡️ Relaying event: ', event.detail, args);
                callback(args);
              }
            } else {
              this.debug('➡️ Relaying event: ', event.detail, args);
              callback(args);
            }
          }
        }

        // Handle tracking events
        if (isTrackingEvent(interaction.event?.type || '') && !isDisabled) {
          const vevNode = document.getElementById(event.detail.nodeId || '');
          if (vevNode) {
            const [projectKey, pageKey] = (vevNode.dataset.path || '').split('/');

            // Dispatch custom event for tracking
            dispatchExternalTrackingEvent(interaction.event?.args, {
              projectKey,
              pageKey,
              contentKey: interaction.trigger?.contentKey,
            });
          }
        }

        // Handle share events
        if (isShareEvent(interaction.event?.type || '') && !isDisabled) {
          triggerShareDialog(interaction.event?.args);
        }
      });
  };

  /**
   * addNode - Register a node with the manager
   * @param id string
   * @param rootNode VevInteractionsNode
   * @param interactions InteractionMap
   * @returns void
   */
  public addNode = (id: string, rootNode: VevInteractionsNode, interactions: InteractionMap) => {
    this.debug('Adding node', id);
    this.nodes.set(id, rootNode);
    this.interactionsMap.set(id, interactions);
    this.reloadTimer();
  };

  /**
   * addNode - Update a node in the manager
   * @param id string
   * @param interactions InteractionMap
   * @returns void
   */
  public updateNode = (id: string, interactions: InteractionMap) => {
    this.debug('Updating node', id);
    this.interactionsMap.set(id, interactions);
    this.reloadTimer();
  };

  // Check if node is registered
  public getNode = (id: string) => {
    return this.nodes.get(id);
  };

  /**
   * addCallback - Add a callback to the manager
   * @param event VevTriggerType
   * @param contentKey string
   * @param callback EventBrokerCallback
   * @returns void
   */
  public addCallback = (
    event: VevTriggerType,
    contentKey: string | undefined,
    callback: EventBrokerCallback,
  ) => {
    if (event && contentKey) {
      this.debug('Adding callback', event, contentKey);
      this.records.set(`${event}.${contentKey}`, callback);
    }
  };

  /**
   * removeCallback - Remove a callback from the manager
   * @param event VevTriggerType
   * @param contentKey string
   * @returns void
   */
  public removeCallback = (event: VevTriggerType, contentKey: string | undefined) => {
    if (event && contentKey) {
      this.debug('Removing callback', event, contentKey);
      this.records.delete(`${event}.${contentKey}`);
    }
  };

  /**
   * destroy - Remove event listener
   * @returns void
   */
  public destroy = () => {
    // Remove event listener
    window.removeEventListener('@@vev', this.handleEvent as EventListener);
  };
}

// Singleton for interactions manager
export function getInteractionsManager() {
  return typeof window.vevInteractionsManager !== 'undefined'
    ? window.vevInteractionsManager
    : initInteractionsManager({});
}
