import { Lexer } from './lexer';
import { Parser } from './parser';

const lexer = new Lexer();
const parser = new Parser();

export function evaluateEventCondition(input: string | undefined, obj?: any): boolean {
  if (!input) {
    return true;
  }
  const lexingResult = lexer.tokenize(input);
  const evaluate = parser.parse(lexingResult);
  return evaluate(obj);
}

export function isEventConditionValid(input: string | undefined): boolean {
  if (!input) {
    return true;
  }

  try {
    const lexingResult = lexer.tokenize(input);
    parser.parse(lexingResult);
    return true;
  } catch (e) {
    return false;
  }
}
