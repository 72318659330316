export function createKeyMap<T>(models: (T & { key: string })[]) {
  const keyMap: { [key: string]: T } = {};
  models.forEach((model) => {
    keyMap[model.key] = model;
  });
  return keyMap;
}

/** best performance in most browsers according to stackoverflow */
export function isObjectEmpty(object: any) {
  for (const i in object) return false;
  return true;
}
