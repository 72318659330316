import { VevDispatchEvent } from '../types';

// Dispatch a VEV event on the window
export function dispatchVevEvent(event: VevDispatchEvent) {
  window.dispatchEvent(
    new CustomEvent('@@vev', {
      detail: event,
    }),
  );
}
