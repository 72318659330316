import { ProjectInteraction } from '../types';
import { hasLink } from './has-link';
import { hasPointerEvent } from './has-pointer-event';

export function getInteractionsNodeType(
  contentKey: string,
  interactions: ProjectInteraction[],
  defaultTag?: string,
): string {
  if (!interactions) return defaultTag || 'div';

  const isButton = interactions
    ?.filter(hasPointerEvent)
    ?.some((i) => i.trigger?.contentKey === contentKey);
  const isLink = interactions?.some(hasLink);
  const hasTarget = interactions?.some((i) => i.event?.contentKey);

  // For links, we also need to get the target
  if (isLink && hasTarget) return 'a';

  if (defaultTag) return defaultTag;
  if (isButton && hasTarget) return 'button';
  return 'div';
}
