import { useCallback, useContext, useEffect } from 'react';
import { EventBrokerContext } from '../../components/vev-event/event-broker-context';
import { dispatchVevEvent } from '../../utils';
import { useContextModel } from '../context';

export const useVevEvent = <T>(eventType: string, handler: (payload: T | unknown) => void) => {
  const model = useContextModel();
  const contentKey = model?.virtualKey || model?.key;
  const { addCallback, removeCallback } = useContext(EventBrokerContext);

  useEffect(() => {
    addCallback(eventType, contentKey, handler);
    return () => {
      removeCallback(eventType, contentKey);
    };
  }, [eventType, contentKey, handler, addCallback, removeCallback]);
};

export const useDispatchVevEvent = () => {
  const model = useContextModel();
  const contentKey = model?.virtualKey || model?.key;
  return useCallback(
    (eventType: string, args?: any) => {
      dispatchVevEvent({
        type: eventType,
        contentKey,
        args,
      });
    },
    [contentKey],
  );
};
