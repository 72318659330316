import React, { createContext, RefObject, useRef } from 'react';
import { IAppState, PkgReducer, VevDispatch, VevReducer } from 'vev';
import { getCurrentExecute } from '../system/utils';
import { createUID } from '../utils';
import { getScrollTop, global } from '../utils/dom';
import { isFunction } from '../utils/type';
import { ModelsProvider, ModelTmpOverridesProvider, VariablesContext } from './context/model';
import { useGlobalStore } from './hooks';

export const states: { [uid: string]: IAppState } = {};
export const emitRefs: { [uid: string]: RefObject<() => void> } = {};
const reducers: VevReducer[] = [];

(global as any).vevStates = states;
(global as any).dispatch = {};

export const DEFAULT_APP_STATE: IAppState = {
  project: undefined,
  root: null,
  scaling: false,
  embed: false,
  scrollTop: getScrollTop(),
  device: 'desktop',
  masterMode: 'desktop',
  zoom: 1,
  viewport: {
    width: global.innerWidth,
    height: global.innerHeight,
    scrollHeight: 0,
  },
  images: {},
  shapes: {},
  models: [],
  pages: [],
  interactions: {
    trigger: { global: { onKeyDown: [], onKeyUp: [], onScroll: [], onTimer: [] }, widget: {} },
    event: { widget: {} },
  },
  animations: {},
  variables: [],
  pkg: {},
  menus: {},
  primaryMenu: undefined,
  pkgStores: {},
  route: { pageKey: '' },
  widgetStorage: {},
  rightPanelTab: undefined,
  tmpOverrides: {},
  settings: {
    devices: [
      {
        mode: 'desktop',
        columnSize: 1024,
        canvasSize: [1440, 900],
      },
      {
        mode: 'tablet',
        columnSize: 768,
        canvasSize: [768, 1024],
      },
      {
        mode: 'mobile',
        columnSize: 320,
        canvasSize: [375, 667],
      },
    ],
  },
};

export function registerReducer(reducer: PkgReducer) {
  const currentPkg = getCurrentExecute()?.id;

  registerGlobalReducer((state, action, payload, pkgKey) => {
    if (pkgKey === currentPkg) {
      const currentState = pkgKey ? state.pkgStores[pkgKey] || {} : {};
      const nextState = reducer(currentState, action as string, payload);
      if (currentState !== nextState && pkgKey) {
        return { ...state, pkgStores: { ...state.pkgStores, [pkgKey]: nextState } };
      }

      return state;
    }

    return state;
  });
}

export function registerGlobalReducer(reducer: VevReducer) {
  reducers.push(reducer);
}

export function getState(uid: string): IAppState {
  return uid ? states[uid] : states[Object.keys(states)[0]];
}

export function extendAppState(newState: IAppState) {
  for (const uid in states) {
    const oldState = states[uid];

    if (oldState.project === newState.project) {
      Object.assign(oldState, {
        models: joinList(oldState.models, newState.models),
        images: { ...oldState.images, ...newState.images },
        shapes: { ...oldState.shapes, ...newState.shapes },
        pkg: { ...oldState.pkg, ...newState.pkg },
        interactions: {
          event: {
            widget: mergeInteractions(
              oldState.interactions?.event?.widget,
              newState.interactions?.event?.widget,
            ),
          },
          trigger: {
            global: mergeInteractions(
              oldState.interactions?.trigger?.global,
              newState.interactions?.trigger?.global,
            ),
            widget: mergeInteractions(
              oldState.interactions?.trigger?.widget,
              newState.interactions?.trigger?.widget,
            ),
          },
        },
      } as IAppState);

      emitRefs[uid].current?.();
    }
  }
}

type StateProviderProps = {
  state?: Partial<IAppState>;
  children: React.ReactNode;
};

type StateListener = (state: IAppState) => void;
type ContextState = [(listener: StateListener) => () => void, VevDispatch, string];

export const StateContext = createContext<ContextState>([() => () => {}, () => {}, '']);

export function StateProvider({ state: initState, children }: StateProviderProps) {
  const contextState = useRef<ContextState>();
  const listeners = useRef<StateListener[]>([]);
  const pendingTimeout = useRef<number>();
  const emitRef = useRef<() => void>(() => {
    if (pendingTimeout.current) return;
    pendingTimeout.current = self.setTimeout(() => {
      pendingTimeout.current = 0;

      for (const listener of listeners.current) {
        const stateKey = contextState.current?.[2];
        if (stateKey) {
          listener(states[stateKey]);
        }
      }
    }, 1);
  });

  if (!contextState.current) {
    const uid = createUID();
    listeners.current = [oldNotify];
    states[uid] = { ...DEFAULT_APP_STATE, ...initState };
    emitRefs[uid] = emitRef;

    contextState.current = [
      (listener) => {
        const index = listeners.current.indexOf(listener);
        if (index === -1) listeners.current.push(listener);
        listener(states[uid]);

        return () => {
          const index = listeners.current.indexOf(listener);
          if (index !== -1) listeners.current.splice(index, 1);
        };
      },
      (action, payload, pkgKey) => {
        if (typeof action === 'object') {
          states[uid] = { ...states[uid], ...(action as any) };

          emitRef.current();
        } else {
          for (const reducer of reducers) {
            const currentState = states[uid];
            const nextState = reducer(currentState, action, payload, pkgKey);

            if (nextState && currentState !== nextState) {
              states[uid] = nextState;
              emitRef.current();
            }
          }
        }
      },
      uid,
    ];

    (global as any).dispatch[uid] = contextState.current[1];
  }

  return (
    <StateContext.Provider value={contextState.current}>
      <VariablesProviderWrapper>
        <ModelsProviderWrapper>
          <ModelTmpOverridesWrapper>{children}</ModelTmpOverridesWrapper>
        </ModelsProviderWrapper>
      </VariablesProviderWrapper>
    </StateContext.Provider>
  );
}

/** Temporary wrapper until we replace the current ugly state architecture with context */
function ModelsProviderWrapper({ children }: { children: React.ReactNode }) {
  const models = useGlobalStore((s) => s.models);
  return <ModelsProvider models={models}>{children}</ModelsProvider>;
}

function ModelTmpOverridesWrapper({ children }: { children: React.ReactNode }) {
  const tmpOverrides = useGlobalStore((s) => s.tmpOverrides);
  return (
    <ModelTmpOverridesProvider tmpOverrides={tmpOverrides}>{children}</ModelTmpOverridesProvider>
  );
}

function VariablesProviderWrapper({ children }: { children: React.ReactNode }) {
  const variables = useGlobalStore((s) => s.variables);
  return <VariablesContext.Provider value={variables}>{children}</VariablesContext.Provider>;
}

const oldListeners: StateListener[] = [];

function oldNotify(s: IAppState) {
  for (const l of oldListeners) l(s);
}

export function store(attr: keyof IAppState, cb: (state: any) => void) {
  console.warn('The store function is deprecated');

  if (cb && isFunction(cb)) {
    let stateId = Object.keys(states)[0];
    let prev = stateId && states[stateId][attr];
    cb(prev);
    const func: StateListener = (s) => {
      if (!stateId) stateId = Object.keys(states)[0];
      const next = s[attr];
      if (next !== prev) {
        prev = next;
        cb(next);
      }
    };
    oldListeners.push(func);
    return () => {
      const index = oldListeners.indexOf(func);
      oldListeners.splice(index, 1);
    };
  }
}

function joinList<T extends { key: string }>(l1: T[], l2: T[]): T[] {
  const res: Record<string, T> = {};
  for (const item of l1) res[item.key] = item;
  for (const item of l2) res[item.key] = item;
  return Object.values(res);
}

function mergeInteractions<T extends { key: string }>(
  o1?: Record<string, T[]>,
  o2?: Record<string, T[]>,
): Record<string, T[]> {
  const res: Record<string, T[]> = {};

  if (o1) {
    Object.keys(o1).forEach((key) => {
      res[key] = o1[key];
    });
  }

  if (o2) {
    Object.keys(o2).forEach((key) => {
      if (res[key]) {
        res[key] = joinList(res[key], o2[key]);
      } else {
        res[key] = o2[key];
      }
    });
  }

  return res;
}
