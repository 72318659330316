import { GLOBAL_EVENT_TYPES } from '../types';

// Set visibility of a node
export function setVisibility(nodeId: string, type: string): void {
  const nodeEl = document.getElementById(nodeId);
  const currentClassList = nodeEl?.classList;
  const isHidden = currentClassList?.contains('__hide');
  const isDefaultHidden = nodeEl?.clientWidth === 0 && nodeEl?.clientHeight === 0;

  switch (type) {
    case GLOBAL_EVENT_TYPES.SHOW:
      currentClassList?.remove('__hide');
      currentClassList?.add('__show');
      return;
    case GLOBAL_EVENT_TYPES.HIDE:
      currentClassList?.remove('__show');
      currentClassList?.add('__hide');
      return;
    case GLOBAL_EVENT_TYPES.TOGGLE:
      setVisibility(
        nodeId,
        isHidden || isDefaultHidden ? GLOBAL_EVENT_TYPES.SHOW : GLOBAL_EVENT_TYPES.HIDE,
      );
      return;
    default:
  }
}
