import { GLOBAL_EVENT_TYPES } from '../types';

// Set visibility of a node
export function setSticky(nodeId: string, type?: string, offset?: number): void {
  const nodeEl = document.getElementById(nodeId);
  const currentClassList = nodeEl?.classList;

  switch (type) {
    case GLOBAL_EVENT_TYPES.STICK:
      currentClassList?.add('__sticky');
      nodeEl?.setAttribute('style', `top: ${offset || 0}px`);
      return;
    case GLOBAL_EVENT_TYPES.UNSTICK:
      currentClassList?.remove('__sticky');
      nodeEl?.setAttribute('style', `top: 0`);
      return;
    default:
  }
}
