import { useGlobalStore } from '../../core/';
import { useEffect, useRef } from 'react';

export function useGlobalEvents() {
  const prevScroll = useRef({
    scrollTop: 0,
    scrollHeight: 0,
    height: 0,
  });

  // Attach scroll listeners
  useGlobalStore(({ scrollTop, viewport: { height, scrollHeight } }) => {
    if (
      prevScroll.current.scrollHeight !== scrollHeight ||
      prevScroll.current.height !== height ||
      prevScroll.current.scrollTop !== scrollTop
    ) {
      prevScroll.current = { scrollHeight, height, scrollTop };

      const percentage = Math.trunc(((scrollTop + height) / scrollHeight) * 100);
      const customEvent = new CustomEvent('@@vev', {
        detail: {
          type: 'onScroll',
          args: {
            scrollTop,
            scrollHeight,
            height,
            percentage,
          },
        },
      });
      window.dispatchEvent(customEvent);
    }
  });

  // Attach keyboard listeners
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      const customEvent = new CustomEvent('@@vev', {
        detail: {
          type: 'onKeyDown',
          args: {
            key: e.key,
          },
        },
      });
      window.dispatchEvent(customEvent);
    });
    window.addEventListener('keyup', (e) => {
      const customEvent = new CustomEvent('@@vev', {
        detail: {
          type: 'onKeyUp',
          args: {
            key: e.key,
          },
        },
      });
      window.dispatchEvent(customEvent);
    });
  }, []);
}
