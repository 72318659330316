import { Variable } from './types';
import { getStateVariables, ModelVariables } from './get-state-variables';
import { attributePathToObject } from './attribute-path-to-object';
import { isEqual } from 'lodash';

declare global {
  interface Window {
    variable: VariableManagerImpl;
    variable_dbg: boolean;
    dispatch: any;
  }
}

class VariableManagerImpl {
  private variables: Map<string, Variable> = new Map();
  private initialVariables: Variable[] = [];
  private windowRef: Window;
  private scope = '';

  constructor() {
    this.log('Initialized VariableManager');
    this.windowRef = window;
  }

  initializeVariables(scope: string, initialVariables: Variable[]): void {
    this.log('Initialize Variables', initialVariables);
    this.scope = scope;
    this.variables = new Map();
    this.initialVariables = initialVariables;
    initialVariables.forEach((variable) => {
      this.variables.set(variable.key, variable);
    });
  }

  bindWindow(bindWindow: Window): void {
    this.windowRef = bindWindow;
  }

  resetVariables() {
    this.initialVariables.forEach((variable) => {
      this.setVariable(variable.key, variable);
    });
  }

  setVariable(key: string, variable: Partial<Variable>) {
    this.log('Set variable', variable);
    const prev = this.variables.get(key);

    if (prev && prev.value === variable.value) {
      return;
    } else if (prev && prev.value !== variable.value) {
      const update = { ...prev, ...variable };
      this.variables.set(key, update);
      this.dispatchChange(update);
    } else {
      this.variables.set(key, variable as Variable);
      this.dispatchChange(variable as Variable);
    }
  }

  resetVariable(key: string) {
    this.log('Reset variable', key);
    const initialValue = this.initialVariables.find((v) => v.key === key);
    if (initialValue) {
      this.setVariable(key, initialValue);
    }
  }

  getVariable(key: string) {
    return this.variables.get(key);
  }

  private dispatchChange(update: Variable) {
    // Update CSS variables
    if (update.type !== 'text') {
      const projectElement = document.querySelector(`.vev .p${this.scope}`) as HTMLElement;
      if (projectElement) {
        projectElement.style.setProperty(`--vev-${update.key}`, update.value);
      }
    }

    this.dispatchStateChanges(update);
  }

  /**
   * Updates the app state for anything using the global store (useVariable hook)
   */
  private dispatchStateChanges(update: Variable) {
    const variables: Variable[] = Array.from(this.variables.values()).filter(
      (v) => v.key !== update.key,
    );

    variables.push(update);

    Object.keys(this.windowRef.dispatch).forEach((stateKey: string) => {
      const dispatch = this.windowRef.dispatch[stateKey];
      dispatch('variables', variables);
    });
  }

  private log(...data: any[]) {
    if (window.variable_dbg) {
      console.log('🟢 VariableManager | ', ...data);
    }
  }
}

export const VariableManager = new VariableManagerImpl();
