type MetaData = {
  projectKey: string;
  pageKey: string;
  contentKey?: string;
};

export function dispatchExternalTrackingEvent(
  data: string | { [key: string]: string } | any = {},
  { projectKey, pageKey, contentKey }: MetaData,
) {
  const { event } = data;
  if (!event) return;

  window.dispatchEvent(
    new CustomEvent('vev.track', {
      detail: {
        type: event?.type,
        data: (event.data || []).reduce(
          (acc: any, curr: any) => ({
            ...acc,
            [curr?.data?.key]: curr?.data?.value,
          }),
          {},
        ),
        metaData: {
          projectKey,
          pageKey,
          timestamp: Date.now(),
          contentKey,
        },
      },
    }),
  );
}
