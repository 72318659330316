import { IAppState, IReducerState } from 'vev';
import { getScrollHeight, getViewHeight, getViewWidth } from '../utils/dom';
import { pageKeyByPath } from '../utils/route';
import { DEFAULT_APP_STATE, registerGlobalReducer } from './state';

function isAppStateAttr(attr: keyof IReducerState): attr is keyof IAppState {
  return Object.prototype.hasOwnProperty.call(DEFAULT_APP_STATE, attr);
}

registerGlobalReducer((state, action, payload, pkgKey) => {
  if (action === 'set-route-path') {
    const pageKey = pageKeyByPath(payload, state.pages, state.dir);
    if (pageKey) return { ...state, route: { pageKey } };
  }

  if (action === 'set-root') {
    return {
      ...state,
      root: payload,
    };
  }

  if (action === 'update-viewport') {
    return {
      ...state,
      viewport: {
        height: getViewHeight(),
        width: getViewWidth(),
        scrollHeight: getScrollHeight(),
      },
    };
  }

  if (action === 'widget-state') {
    return {
      ...state,
      widgetState: {
        ...state?.widgetState,
        [pkgKey as string]: payload,
      },
    };
  }

  if (action === 'widget-content') {
    return {
      ...state,
      models: state.models.map((model) => {
        if (model.key !== payload.modelKey) return model;
        return {
          ...model,
          content: { ...model.content, ...payload.content },
        };
      }),
    };
  }

  if (action === 'change-variant') {
    const { variant: tmpVariant, key, toggle } = payload;
    let variant = tmpVariant || null;

    if (toggle && state.tmpOverrides[key]?.variant === variant) {
      const model = state.models.find((m) => m.key === key);
      variant = model?.variant || null;
    }

    return {
      ...state,
      tmpOverrides: {
        ...state.tmpOverrides,
        [payload.key]: { ...(state.tmpOverrides[payload.key] || {}), variant },
      },
    };
  }

  if (action === 'reset-overrides') {
    return {
      ...state,
      tmpOverrides: {},
    };
  }
  // Only update state if action is attr in app state and value changed
  if (isAppStateAttr(action) && state[action] !== payload) return { ...state, [action]: payload };

  return state;
});
