export enum TokenTypes {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  Bigger = 'Bigger',
  Smaller = 'Smaller',
  Not = 'Not',
  BiggerEqual = 'BiggerEqual',
  SmallerEqual = 'SmallerEqual',
  ObjectAccessOperator = 'ObjectAccessOperator',
  Identifier = 'Identifier',
  Number = 'Number',
  String = 'String',
  True = 'True',
  False = 'False',
  And = 'And',
  Or = 'Or',
  EOF = 'EOF',
}

export interface Token {
  type: TokenTypes;
  pattern?: string;
}

export interface Tokenized {
  type: TokenTypes;
  value?: string;
}

const tokens: { [key: string]: TokenTypes } = {
  '': TokenTypes.EOF,
  '!': TokenTypes.Not,
  '=': TokenTypes.Equal,
  '>': TokenTypes.Bigger,
  '<': TokenTypes.Smaller,
  '.': TokenTypes.ObjectAccessOperator,
  true: TokenTypes.True,
  false: TokenTypes.False,
};

export class Lexer {
  input = '';
  pos = 0;

  constructor() {}

  private isHyphen(c: string) {
    return c === '-';
  }

  private isNumber(c: string) {
    return c >= '0' && c <= '9';
  }

  private processNumber(): Tokenized {
    let endPos = this.pos + 1;
    while (endPos < this.input.length && this.isNumber(this.input[endPos])) {
      endPos++;
    }

    const token = {
      type: TokenTypes.Number,
      value: this.input.substring(this.pos, endPos),
    };
    this.pos = endPos;
    return token;
  }

  private isCharacter(c: string) {
    return (c >= 'a' && c <= 'z') || (c >= 'A' && c <= 'Z') || c === '_' || c === '-';
  }

  private processIdentifierOrOperator(): Tokenized {
    let endPos = this.pos + 1;
    while (endPos < this.input.length && this.isCharacter(this.input[endPos])) {
      endPos++;
    }

    let token: Tokenized;
    const result = this.input.substring(this.pos, endPos);
    if (result === 'AND' || result === 'OR') {
      token = {
        type: result === 'AND' ? TokenTypes.And : TokenTypes.Or,
        value: this.input.substring(this.pos, endPos),
      };
    } else {
      token = {
        type: TokenTypes.Identifier,
        value: this.input.substring(this.pos, endPos),
      };
    }

    this.pos = endPos;
    return token;
  }

  tokenize(input: string) {
    this.input = input;
    this.pos = 0;
    const result: Tokenized[] = [];
    while (this.pos < this.input.length) {
      if (this.input[this.pos] === ' ') {
        this.pos++;
        continue;
      }

      const match = tokens[this.input[this.pos]];
      if (match) {
        result.push({
          type: match,
          value: this.input[this.pos],
        });
        this.pos++;
      } else {
        if (
          this.isNumber(this.input[this.pos]) ||
          (this.isHyphen(this.input[this.pos]) && this.isNumber(this.input[this.pos + 1]))
        ) {
          result.push(this.processNumber());
        } else if (this.isCharacter(this.input[this.pos])) {
          result.push(this.processIdentifierOrOperator());
        } else {
          throw Error(`Unknown symbol ${this.input[this.pos]}`);
        }
      }
    }

    return result;
  }
}
