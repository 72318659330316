import { createContext, default as React, useContext, useMemo } from 'react';
import { IContent, Variable } from 'vev';
import { applyVariables, isString } from '../../utils';
import { createKeyMap, isObjectEmpty } from './utils';
import { VariantContext, VariantOverrideProvider } from './variant';

type ModelsContextModel = { [key: string]: IContent };
type VirtualModelContextModel = {
  masterKey: string;
  instanceKey: string;
  instanceKeyChain: string[];
  overrides: { [modelKey: string]: any };
  variant?: string;
};

const ModelsContext = createContext<ModelsContextModel | null>(null);
const ModelContext = createContext<IContent>({ key: '' });
const VirtualModelContext = createContext<VirtualModelContextModel | null>(null);
const ModelTmpOverridesContext = createContext<{ [key: string]: Partial<IContent> }>({});
export const VariablesContext = createContext<Variable[] | null>([]);

export const ModelProvider = ({
  value,
  children,
}: {
  value: IContent;
  children: React.ReactNode;
}) => {
  const masterKey = value.master;
  if (isString(masterKey)) {
    return (
      <VariantOverrideProvider variantKey={value.variant}>
        <ModelContext.Provider value={value}>
          <VirtualModelProvider
            overrides={value.childContent || {}}
            instanceKey={value.key}
            masterKey={masterKey}
          >
            {children}
          </VirtualModelProvider>
        </ModelContext.Provider>
      </VariantOverrideProvider>
    );
  }
  return <ModelContext.Provider value={value}>{children}</ModelContext.Provider>;
};

export function ModelsProvider({
  models,
  children,
}: {
  models: IContent[];
  children: React.ReactNode;
}) {
  const modelMap = useMemo(() => createKeyMap(models), [models]);
  return <ModelsContext.Provider value={modelMap}>{children}</ModelsContext.Provider>;
}

export function ModelTmpOverridesProvider({
  tmpOverrides,
  children,
}: {
  tmpOverrides: { [key: string]: Partial<IContent> };
  children: React.ReactNode;
}) {
  return (
    <ModelTmpOverridesContext.Provider value={tmpOverrides}>
      {children}
    </ModelTmpOverridesContext.Provider>
  );
}

export function VirtualModelProvider({
  overrides,
  masterKey,
  instanceKey,
  children,
}: {
  overrides?: { [modelKey: string]: any };
  masterKey: string;
  instanceKey: string;
  children: React.ReactNode;
}) {
  const virtualParent = useContext(VirtualModelContext);

  const virtualModel: VirtualModelContextModel = useMemo(() => {
    const instanceKeyChain = virtualParent?.instanceKeyChain.slice() || [];
    instanceKeyChain.unshift(instanceKey);

    const parentOverrides = virtualParent?.overrides || {};
    const newOverrides = { ...overrides };

    for (const parentKey in parentOverrides) {
      if (newOverrides[parentKey]) {
        // Parent override is the top instance, so that should override the new overrides
        newOverrides[parentKey] = { ...newOverrides[parentKey], ...parentOverrides[parentKey] };
      } else newOverrides[parentKey] = parentOverrides[parentKey];
    }

    return {
      masterKey,
      instanceKey,
      instanceKeyChain,
      overrides: newOverrides,
    };
  }, [masterKey, instanceKey, virtualParent, overrides]);

  return (
    <VirtualModelContext.Provider value={virtualModel}>{children}</VirtualModelContext.Provider>
  );
}

export function useContextModel(key?: string): IContent | undefined {
  const models = useContext(ModelsContext);
  const tmpOverrides = useContext(ModelTmpOverridesContext);
  const contextState = useContext(ModelContext);
  const virtualModel = useContext(VirtualModelContext);

  const model = models?.[key || ''];
  const master = models?.[model?.master?.toString() || ''];
  const modelKey = key || contextState?.key || '';
  const instanceKeyChain = virtualModel?.instanceKeyChain;
  const variantContext = useContext(VariantContext);
  const variables = useContext(VariablesContext);

  const virtualKey = useMemo(() => {
    if (!instanceKeyChain?.length || !key || instanceKeyChain.includes(key)) return key;
    return `${key}-${instanceKeyChain.join('-')}`;
  }, [key, instanceKeyChain]);

  const tmpOverride = useMemo(() => {
    return virtualKey ? tmpOverrides[virtualKey] : undefined;
  }, [virtualKey, tmpOverrides]);

  const variant =
    models?.[tmpOverride?.variant !== undefined ? tmpOverride?.variant : model?.variant || ''];

  const variantModelOverrides: Partial<IContent> | undefined = useMemo(() => {
    return variant?.overrides?.[master?.key || modelKey];
  }, [master, variant, modelKey]);

  return useMemo(() => {
    if (!key) return { ...contextState };
    const instanceKeyChain = virtualModel?.instanceKeyChain || [];
    let override = virtualModel?.overrides[key];
    let contentOverride = { ...override?.content };
    let attrOverrides = { ...override?.attrs };

    let variantOverrides: Partial<IContent>;
    let variantContentOverrides: Partial<IContent>;
    let variantAttrOverrides: Partial<IContent>;

    if (master?.key) {
      // Model is a instance, remove children from variant context
      const { children: ignore, attrs, ...rest } = variantContext?.[key] || {};
      variantAttrOverrides = { ...variantContext?.[master?.key]?.attrs, ...attrs };
      variantContentOverrides = { ...variantContext?.[master?.key]?.content, ...rest?.content };
      variantOverrides = {
        ...variantModelOverrides,
        ...variantContext?.[master?.key],
        ...rest,
      };
    } else {
      variantAttrOverrides = { ...variantModelOverrides?.attrs, ...variantContext?.[key]?.attrs };
      variantContentOverrides = {
        ...variantModelOverrides?.content,
        ...variantContext?.[key]?.content,
      };
      variantOverrides = {
        ...variantModelOverrides,
        ...variantContext?.[key],
      };
    }

    for (let i = 0; i < instanceKeyChain.length - 1; i++) {
      const path = `${key}-${instanceKeyChain.slice(0, i + 1).join('-')}`;
      if (virtualModel?.overrides[path]) {
        override = { ...override, ...virtualModel?.overrides[path] };
        contentOverride = { ...contentOverride, ...virtualModel?.overrides[path]?.content };
        attrOverrides = { ...attrOverrides, ...virtualModel?.overrides[path]?.attrs };
      }

      if (variantContext?.[path]) {
        variantOverrides = { ...variantOverrides, ...variantContext[path] };
        variantContentOverrides = { ...variantOverrides.content, ...variantContext[path]?.content };
        variantAttrOverrides = { ...variantOverrides.attrs, ...variantContext[path]?.attrs };
      }
    }

    if (override || master || !isObjectEmpty(variantOverrides)) {
      const cl = [];

      if (override?.type) cl.push(override.type);
      else if (model?.type) cl.push(model.type);

      if (override?.master) cl.push(override.master);
      else if (master) cl.push(master.key);

      if (override?.preset) cl.push(override.preset);
      else if (model?.preset) cl.push(model.preset);

      // prevent children/actions from overrides
      const actions = variantOverrides?.actions || model?.actions || [];
      const children = variantOverrides?.children || master?.children || model?.children || [];
      const contentChildren = variantOverrides?.content?.children || model?.content?.children || [];

      return {
        ...master,
        ...model,
        ...variantOverrides,
        ...override,
        ...tmpOverride,
        actions,
        children,
        virtualKey,
        fromModel: key,
        cl: cl.join(' '),
        attrs: {
          ...master?.attrs,
          ...model?.attrs,
          ...variantAttrOverrides,
          ...attrOverrides,
        },
        content: {
          ...master?.content,
          ...model?.content,
          ...variantContentOverrides,
          ...contentOverride,
          children: contentChildren,
        },
      };
    } else if (virtualModel) {
      return {
        ...model,
        fromModel: key,
        key: virtualKey,
      };
    }
    const [content, variablesApplied] = applyVariables(model?.content, variables);
    return (
      model && {
        ...model,
        content,
        variablesApplied,
      }
    );
  }, [
    key,
    model,
    virtualModel,
    contextState,
    master,
    variantContext,
    variantModelOverrides,
    tmpOverride,
    virtualKey,
    variables,
  ]);
}

export function useModels() {
  return useContext(ModelsContext);
}
