import { GLOBAL_EVENTS } from '../constants';
import { EVENT_SCOPE } from '../types';

export function isTargetGlobal(type?: string): boolean {
  if (type) {
    const match = GLOBAL_EVENTS.find((t) => t.value === type);
    return match?.scope === EVENT_SCOPE.EXTERNAL || match?.scope === EVENT_SCOPE.PAGE;
  }

  return false;
}
