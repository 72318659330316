import { createContext } from 'react';
import { VevTriggerType } from '@vev/interactions';

export type EventBrokerCallback = (args?: Record<string, string>) => void;
export type EventBrokerCallbackRecord = Record<string, EventBrokerCallback>;

interface EventBroker {
  addCallback: (
    event: VevTriggerType,
    contentKey: string | undefined,
    callback: EventBrokerCallback,
  ) => void;
  removeCallback: (event: VevTriggerType, contentKey: string | undefined) => void;
}

export const EventBrokerContext = createContext<EventBroker>({
  addCallback: () => {},
  removeCallback: () => {},
});
