/*
 * multi_condition_expression     ::= comparison_expression ([And | Or] comparison_expression)*
 * comparison_expression          ::= object comp object
 * object                         ::= [Identifier (ObjectAccess Identifier)* | value].
 * comp                           ::= [Equal | NotEqual | BiggerEqual | SmallerEqual | Bigger | Smaller]
 * value                          ::= [Number | String | True | False]
 * and                            ::= And
 * or                             ::= Or
 */

import { Tokenized, TokenTypes } from './lexer';

type ValueType = number | string | boolean;

const CONDITION_ACTIONS = {
  [TokenTypes.Equal]: (a: any, b: any) => a === b,
  [TokenTypes.NotEqual]: (a: any, b: any) => a !== b,
  [TokenTypes.BiggerEqual]: (a: any, b: any) => a >= b,
  [TokenTypes.SmallerEqual]: (a: any, b: any) => a <= b,
  [TokenTypes.Bigger]: (a: any, b: any) => a > b,
  [TokenTypes.Smaller]: (a: any, b: any) => a < b,
} as const;

const MULTI_CONDITION_ACTIONS = {
  [TokenTypes.And]: (a: any, b: any) => a && b,
  [TokenTypes.Or]: (a: any, b: any) => a || b,
};

export class Parser {
  constructor() {}

  parse(tokens: Tokenized[]) {
    return this.multiConditionExpression(tokens);
  }

  private multiConditionExpression(tokens: Tokenized[]): (scope: any) => boolean {
    const comparisonExpression = this.comparisonExpression(tokens);
    if (tokens[0] && (tokens[0].type === TokenTypes.And || tokens[0].type === TokenTypes.Or)) {
      const andOr = tokens.shift()?.type;
      if (!andOr || !(andOr === TokenTypes.And || andOr === TokenTypes.Or)) {
        throw new Error('Parsing error: expected And/Or expression');
      }
      const multiConditionAction = MULTI_CONDITION_ACTIONS[andOr];
      const comparisonExpressionNext = this.multiConditionExpression(tokens);
      return (scope) => {
        return multiConditionAction(comparisonExpression(scope), comparisonExpressionNext(scope));
      };
    }

    if (tokens[0] && tokens[0].type !== TokenTypes.EOF) {
      throw new Error(`Expected EOF got: ${JSON.stringify(tokens[0])}`);
    }

    return comparisonExpression;
  }

  private comparisonExpression(tokens: Tokenized[]): (scope: any) => false | boolean {
    const LHSObjectPathOrValue = this.object(tokens);
    const comparison = this.comparison(tokens);
    const RHSObjectPathOrValue = this.object(tokens);

    // path,value
    if (Array.isArray(LHSObjectPathOrValue) && !Array.isArray(RHSObjectPathOrValue)) {
      return (scope: any) => {
        return comparison(this.getObjectValue(LHSObjectPathOrValue, scope), RHSObjectPathOrValue);
      };
    }

    // path,path
    if (Array.isArray(LHSObjectPathOrValue) && Array.isArray(RHSObjectPathOrValue)) {
      return (scope: any) => {
        return comparison(
          this.getObjectValue(LHSObjectPathOrValue, scope),
          this.getObjectValue(RHSObjectPathOrValue, scope),
        );
      };
    }

    // value,value
    if (!Array.isArray(LHSObjectPathOrValue) && !Array.isArray(RHSObjectPathOrValue)) {
      return () => {
        return comparison(LHSObjectPathOrValue, RHSObjectPathOrValue);
      };
    }

    // value, path
    if (!Array.isArray(LHSObjectPathOrValue) && Array.isArray(RHSObjectPathOrValue)) {
      return (scope: any) => {
        return comparison(LHSObjectPathOrValue, this.getObjectValue(RHSObjectPathOrValue, scope));
      };
    }

    throw Error('Parsing error in comparisonExpression');
  }

  private object(tokens: Tokenized[]): string[] | ValueType {
    const objectPath: string[] = [];

    if (
      (tokens[0].type !== TokenTypes.Identifier &&
        tokens[0].type !== TokenTypes.ObjectAccessOperator) ||
      tokens[0].value === 'false' || // Hack to not have to convert to boolean in comparison function
      tokens[0].value === 'true'
    ) {
      return this.value(tokens);
    }

    const tokenValue = tokens.shift()?.value;

    if (tokens[0] && tokens[0].type === TokenTypes.ObjectAccessOperator) {
      tokens.shift();
      const subPath = this.object(tokens);
      if (Array.isArray(subPath)) {
        objectPath.unshift(...subPath);
      } else {
        throw new Error(`Expected object identifier got ${subPath}`);
      }
    }

    if (!tokenValue) {
      throw new Error('Expected token value in object expression');
    }

    objectPath.unshift(tokenValue);

    return objectPath;
  }

  private comparison(tokens: Tokenized[]) {
    const tokenType = tokens.shift()?.type;
    if (tokenType === TokenTypes.Equal) {
      return CONDITION_ACTIONS[TokenTypes.Equal];
    }

    if (tokenType === TokenTypes.Not) {
      if (tokens[0].type === TokenTypes.Equal) {
        tokens.shift();
        return CONDITION_ACTIONS[TokenTypes.NotEqual];
      }
      throw Error(`Expected token Not got ${tokens[0].type}`);
    }

    if (tokenType === TokenTypes.Bigger) {
      if (tokens[0].type === TokenTypes.Equal) {
        tokens.shift();
        return CONDITION_ACTIONS[TokenTypes.BiggerEqual];
      }
      return CONDITION_ACTIONS[TokenTypes.Bigger];
    }

    if (tokenType === TokenTypes.Smaller) {
      if (tokens[0].type === TokenTypes.Equal) {
        tokens.shift();
        return CONDITION_ACTIONS[TokenTypes.SmallerEqual];
      }
      return CONDITION_ACTIONS[TokenTypes.Smaller];
    }

    throw new Error(`Expected comparison got: ${JSON.stringify(tokenType)}`);
  }

  private value(tokens: Tokenized[]): ValueType {
    const token = tokens.shift();
    if (!token) throw new Error(`Expected value got: ${JSON.stringify(token)}`);

    if (token.type === TokenTypes.Number) {
      if (!token.value) throw new Error(`Expected value for token: ${JSON.stringify(token)}`);
      return parseInt(token.value);
    }

    // String
    if (token.type === TokenTypes.Identifier) {
      if (token.value === 'true') {
        return true;
      }
      if (token.value === 'false') {
        return false;
      }

      if (!token.value) throw new Error(`Expected value for token: ${JSON.stringify(token)}`);
      return token.value;
    }

    if (token.type === TokenTypes.True) {
      return true;
    }

    if (token.type === TokenTypes.False) {
      return false;
    }

    throw new Error(`Expected value got: ${JSON.stringify(token)}`);
  }

  private getObjectValue(objectPath: string[], scope: any): ValueType {
    for (const attr of objectPath) {
      if (scope) {
        scope = scope[attr];
      }
    }

    // Hack for string comparisons without needing ' or "
    if (scope === undefined) {
      return objectPath[0];
    }

    return scope;
  }
}
