import { InteractionMap, ProjectInteraction } from '../types';
import { isTriggerGlobal } from './is-trigger-global';

export function interactionMapToArray(interactions: InteractionMap): ProjectInteraction[] {
  if (!interactions) return [];
  const result: ProjectInteraction[] = [];
  const writtenInteractions = new Map<string, boolean>();

  if (interactions.event?.widget) {
    for (const key of Object.keys(interactions.event.widget)) {
      if (interactions.event.widget[key]) {
        for (const interaction of interactions.event.widget[key]) {
          // Skip duplicates
          if (writtenInteractions.has(interaction.key)) continue;
          writtenInteractions.set(interaction.key, true);
          result.push(interaction);
        }
      }
    }
  }

  if (interactions.trigger?.widget) {
    for (const key of Object.keys(interactions.trigger.widget)) {
      if (interactions.event.widget[key]) {
        for (const interaction of interactions.trigger.widget[key]) {
          // Skip duplicates
          if (writtenInteractions.has(interaction.key)) continue;
          writtenInteractions.set(interaction.key, true);
          result.push(interaction);
        }
      }
    }
  }

  if (interactions.trigger?.global) {
    for (const key of Object.keys(interactions.trigger.global)) {
      if (isTriggerGlobal(key)) {
        for (const interaction of interactions.trigger.global[key]) {
          // Skip duplicates
          if (writtenInteractions.has(interaction.key)) continue;
          writtenInteractions.set(interaction.key, true);
          result.push(interaction);
        }
      }
    }
  }

  return result;
}
