export function resetSticky(nodeEl: HTMLElement): void {
  // Remove the offset and sticky from nodeEl if it is sticky
  if (nodeEl?.classList.contains('__sticky')) {
    nodeEl?.setAttribute('style', `top: 0`);
    nodeEl?.classList.remove('__sticky');
  }

  // Remove the offset and class from all children of nodeEl
  nodeEl?.querySelectorAll('.__sticky').forEach((el) => {
    el.setAttribute('style', `top: 0`);
    el.classList.remove('__sticky');
  });
}
