export async function triggerShareDialog(args: Record<string, string> | undefined) {
  const shareData = {
    title: args?.title || document.title,
    text: args?.text || document.title,
    url: args?.url || window.location.href,
  };

  try {
    await navigator.share(shareData);
  } catch (err) {
    await navigator.clipboard.writeText(shareData.url);
  }
}
