import {
  dispatchVevEvent,
  convertToDomEventType,
  isNativeEvent,
  setVisibility,
  setSticky,
  resetVisibility,
  resetSticky,
  isCustomEvent,
  getNodeFirstChild,
  getNodeParent,
} from './utils';
import {
  InteractionMap,
  ProjectInteraction,
  GLOBAL_EVENT_TYPES,
  CUSTOM_TRIGGER_TYPES,
  VevTriggerType,
  InteractionsNodeArgs,
  AnimationKeyframesMap,
} from './types';
import { getInteractionsManager, initInteractionsManager } from './interactions-manager';
import { playAnimation } from './animation';

const logger = (...data: any[]) => console.log('🟠 InteractionsNode -> ', ...data);

/**
 * Initialize interactions for a vev app
 *
 * @param id                            - Unique identifier for the scope
 * @param rootNode                      - Root node/scope for this controller
 * @param interactions                  - Relevant interactions for the context
 * @param debug         boolean         - Enable/disable logging
 * @param disabled      boolean         - Disable actions (in editor mode)
 * @param legacy        boolean         - Enable legacy viewer mode (Account for extra divs on frames)
 * @returns destroy()                   - Function to destroy the interactions (remove event listeners, etc.)
 */
export function initInteractionsNode(args: InteractionsNodeArgs) {
  // Ensure browser context
  if (!window) {
    console.error('Window not defined');
    return;
  }

  // Check if manager exists
  if (!(window as any).vevInteractionsManager) {
    initInteractionsManager(args);
  }

  // Check if already initialized
  const nodeId = args.id || 'app';
  const existingNode = window && (window as any).vevInteractionsManager?.getNode(nodeId);
  if (existingNode) {
    existingNode.update(args);
    return;
  }

  const vevInteractionsNode = new VevInteractionsNode(args);
  window &&
    (window as any).vevInteractionsManager?.addNode(nodeId, vevInteractionsNode, args.interactions);
  args.rootNode.setAttribute('data-vev-interactions-root', 'true');

  return () => {
    vevInteractionsNode.destroy();
  };
}

/**
 * VevInteractionsNode Class
 * -> Listen for DOM mutations
 * -> Attach/Detach event listeners to DOM nodes with interactions
 * -> Add callbacks to global manager if exists
 */
export class VevInteractionsNode {
  private id: string;
  private rootNode: HTMLElement;
  private mutationObserver: MutationObserver;
  private interactions: InteractionMap;
  private eventListeners: Map<string, VevTriggerType[]>;
  private intersectionObservers: Map<string, IntersectionObserver>;
  private debug: boolean;
  private animations: AnimationKeyframesMap;
  private legacyMode: boolean;
  private log: (...data: any[]) => void;

  constructor(args: InteractionsNodeArgs) {
    this.id = args.id;
    this.animations = args.animations || {};
    this.rootNode = args.rootNode;
    this.interactions = args.interactions;
    this.eventListeners = new Map();
    this.intersectionObservers = new Map();
    this.debug = !!args.config.debug;
    this.legacyMode = args.config.legacy || false;
    this.log = args.config.debug ? logger : () => {};

    // Initialize MutationObserver
    this.mutationObserver = new MutationObserver(this.handleDomMutation);

    // Attach event listeners to all child nodes with interactions
    this.attachInitialListeners();

    // Start observing DOM mutations
    this.startObservingMutations();
    (window as any).vevInteractionsManager?.updateNode(this.id, args.interactions);

    this.log('Started for root node:', this.id, this.legacyMode ? '(Legacy Mode)' : '');
  }

  public update = (args: InteractionsNodeArgs) => {
    this.log(`node with id ${args.rootNode.id || 'app'} already initialized - Updating`);
    this.interactions = args.interactions;

    // Update manager
    getInteractionsManager();

    // Reset styles and remove listeners
    this.resetNodeStyles();
    this.detachEventListeners();
    this.removeIntersectionObservers();

    // Re-attach event listeners to all child nodes with interactions
    this.attachInitialListeners();
  };

  private attachInitialListeners() {
    this.log('Attaching initial listeners', this.interactions);
    // Attach triggers to all child nodes
    for (const key in this.interactions.trigger?.widget) {
      const node = document.getElementById(key);
      if (node && node instanceof HTMLElement) {
        this.attachTrigger(node);
      }
    }

    // Attach event listeners to all child nodes
    for (const key in this.interactions.event?.widget) {
      const node = document.getElementById(key);
      if (node && node instanceof HTMLElement) {
        this.attachListeners(node);
      }
    }
  }

  private resetNodeStyles() {
    this.log('Resetting node styles');
    resetVisibility(this.rootNode);
    resetSticky(this.rootNode);
  }

  private removeIntersectionObservers() {
    this.log('Removing IntersectionObservers');
    this.intersectionObservers.forEach((observer) => observer.disconnect());
  }

  private startObservingMutations() {
    this.mutationObserver.observe(this.rootNode, {
      childList: true,
      subtree: true,
    });
  }

  private handleDomMutation(mutationsList: MutationRecord[]) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((node) => {
          if (node instanceof HTMLElement) {
            // Check if node has triggers
            // TODO: Main components logic
            if (this.interactions?.trigger?.widget[node.id]) {
              this.attachTrigger(node);
            }
            // TODO: Main components logic
            // Check if node has event listeners
            if (this.interactions?.event?.widget[node.id]) {
              this.attachListeners(node);
            }
          }
        });
      }
    }
  }

  private attachTrigger(node: HTMLElement) {
    this.log('🟡 Attaching event listeners to node:', node.id);

    const interactions = this.interactions?.trigger?.widget[node.id] || [];

    for (const interaction of interactions) {
      const existingListeners = this.eventListeners.get(node.id) || [];
      const triggerType = interaction?.trigger?.type || '';
      const triggerTarget = interaction?.event?.contentKey || '';

      // Abort if already attached with same trigger type
      if (existingListeners.includes(triggerType)) return;

      // Attach native event listener to first child of node
      const isNative = isNativeEvent(triggerType);
      if (isNative) {
        const targetNode = this.legacyMode ? getNodeFirstChild(node) : node;
        this.log('Attaching native event listener:', triggerType, ' to ', targetNode);
        targetNode?.addEventListener(convertToDomEventType(triggerType), this.handleTrigger);
      }

      // Attach custom event listeners
      const isCustom = isCustomEvent(triggerType);
      if (isCustom) {
        const hasVisibleListener = triggerType === CUSTOM_TRIGGER_TYPES.ON_VISIBLE;
        const hasLeaveListener = triggerType === CUSTOM_TRIGGER_TYPES.ON_LEAVE;

        if (hasVisibleListener || hasLeaveListener) {
          const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                // TODO: pass component key
                // node.attributes['data-component-key'];
                this.dispatchEvent(node.id, CUSTOM_TRIGGER_TYPES.ON_VISIBLE);
              } else {
                this.dispatchEvent(node.id, CUSTOM_TRIGGER_TYPES.ON_LEAVE);
              }
            });
          });

          observer.observe(node);
          this.intersectionObservers.set(node.id, observer);
        }
      }

      this.eventListeners.set(node.id, [...existingListeners, triggerType]);

      if (this.debug) {
        const targetNode = this.legacyMode ? getNodeFirstChild(node) : node;
        targetNode?.setAttribute(`data-vev-interaction-${triggerType}`, triggerTarget);
      }
    }
  }

  private attachListeners(node: HTMLElement) {
    // Attach event listeners to the node
    const matches = this.interactions.event.widget[node.id] || [];
    // TODO: add event listeners for main components
    for (const interaction of matches) {
      const eventType = interaction?.event?.type || '';
      const eventTrigger = interaction?.trigger?.contentKey || '';

      getInteractionsManager().addCallback(eventType, node.id, (args: any) => {
        const animationInteraction = args.interaction as ProjectInteraction;

        this.handleEvent(node.id, interaction);

        // Handle animation events
        if (animationInteraction.event?.type === GLOBAL_EVENT_TYPES.ANIMATE) {
          const el = node;
          if (el) {
            const { id } = node;
            playAnimation(animationInteraction, this.animations, id);
            setVisibility(id, GLOBAL_EVENT_TYPES.SHOW);
          }
        }
      });

      if (this.debug) {
        const targetNode = this.legacyMode ? getNodeFirstChild(node) : node;
        targetNode?.setAttribute(`data-vev-interaction-${eventType}`, eventTrigger);
      }
    }
  }

  private handleTrigger = (event: Event) => {
    const target = event?.currentTarget || event?.target;
    const parent = this.legacyMode ? getNodeParent(target as HTMLElement) : (target as HTMLElement);
    const nodeId = parent?.id || '';
    const triggerType = event.type as VevTriggerType;
    this.dispatchEvent(nodeId, triggerType);
  };

  private dispatchEvent = (key: string, type: VevTriggerType) => {
    this.log('Dispatch', key, type);
    const contentKey = key.slice(0, 11);
    const instanceKeyChains: string[] = [];
    if (key.length > 11) instanceKeyChains.push(key.slice(11));
    // TODO: find a way to add componetKey to keychains
    dispatchVevEvent({ type, contentKey, instanceKeyChains, nodeId: this.id });
  };

  private handleEvent = (nodeId: string, interaction: ProjectInteraction) => {
    this.log('handleEvent:', interaction);

    // TODO: Main componnets & variant event

    // Show/hide
    if (
      interaction.event?.type === GLOBAL_EVENT_TYPES.SHOW ||
      interaction.event?.type === GLOBAL_EVENT_TYPES.HIDE ||
      interaction.event?.type === GLOBAL_EVENT_TYPES.TOGGLE
    ) {
      setVisibility(nodeId, interaction.event?.type);
    }

    // Handle sticky events
    if (
      interaction.event?.type === GLOBAL_EVENT_TYPES.STICK ||
      interaction.event?.type === GLOBAL_EVENT_TYPES.UNSTICK
    ) {
      const eventArgs = interaction.event?.args as { [attr: string]: any };
      setSticky(nodeId, interaction.event?.type, eventArgs?.offset || 0);
    }
  };

  private detachEventListeners() {
    // Find and remove event listeners from all nodes
    this.log('Detaching event listeners');
    this.eventListeners.forEach((eventTypes, node) => {
      const nodeEl = document.getElementById(node);
      eventTypes.forEach((eventType) => {
        const targetNode = this.legacyMode ? getNodeFirstChild(nodeEl) : nodeEl;
        targetNode?.removeEventListener(eventType, this.handleTrigger);
      });
    });

    // Clear the event listeners map
    this.eventListeners.clear();
  }

  public destroy() {
    // Disconnect the MutationObserver
    this.mutationObserver.disconnect();

    // Remove event listeners from all nodes
    this.detachEventListeners();

    this.log('Destroyed');
  }
}
