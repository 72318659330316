import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  CUSTOM_TRIGGER_TYPES,
  DOM_TRIGGER_TYPES,
  ProjectInteraction,
  VevTriggerType,
  VevDispatchEvent,
  GLOBAL_EVENT_TYPES,
  playAnimation,
} from '@vev/interactions';
import { useEditorState, useGlobalStateRef, useGlobalStore } from '../hooks';
import { getInteractionsNodeWithAttributes } from '../../utils/interactions';
import { Intersection, dispatchVevEvent } from '..';
import { EventBrokerContext } from '../../components/vev-event/event-broker-context';
import { useSwipe } from '../hooks/use-swipe';
import { getInheritedKeysFromKey } from '@vev/utils';

const VARIANT_EVENTS: string[] = [
  GLOBAL_EVENT_TYPES.CHANGE_VARIANT,
  GLOBAL_EVENT_TYPES.TOGGLE_VARIANT,
];

/**
 * `useInteractions` watches and returns all trigger interactions for a given key
 */
export function useProjectInteractionTriggers(
  widgetKey: string,
  componentKey?: string,
): ProjectInteraction[] {
  const interactions = useGlobalStore((state) => state.interactions);
  const inheritedKeys = getInheritedKeysFromKey(widgetKey);
  const modelInteractions = interactions.trigger?.widget[widgetKey] || [];

  if (componentKey) {
    modelInteractions.push(...(interactions.trigger?.widget?.[componentKey] || []));
  }

  for (const inheritedKey of inheritedKeys) {
    // key = "model-instance"
    // inheritedKey = "model"
    // filtered = all interactions where trigger = "model"
    const filtered = interactions.trigger?.widget[inheritedKey] || [];
    // scope = "-instance"
    const scope = widgetKey.replace(inheritedKey, '');
    // res = Interactions including scope
    modelInteractions.push(...filtered.map((i) => ({ ...i, scope })));
  }

  return modelInteractions;
}

/**
 * `useInteractions` watches and returns all event interactions for a given key
 */
export function useProjectInteractionEvents(widgetKey: string): ProjectInteraction[] {
  const interactions = useGlobalStore((state) => state.interactions);
  if (!widgetKey) return [];
  const modelInteractions = interactions.event?.widget[widgetKey] || [];
  const inheritedKeys = getInheritedKeysFromKey(widgetKey);

  for (const inheritedKey of inheritedKeys) {
    // modelKey = "model-instance"
    // inheritedKey = "model"
    // filtered = all interactions where trigger = "model"
    const filtered = interactions.event?.widget[inheritedKey] || [];
    // scope = "-instance"
    const scope = widgetKey.replace(inheritedKey, '');
    // res = Interactions including scope
    modelInteractions.push(...filtered.map((i) => ({ ...i, scope })));
  }

  return modelInteractions;
}

function isNativeEvent(value: string): value is DOM_TRIGGER_TYPES {
  return Object.values(DOM_TRIGGER_TYPES).includes(value as DOM_TRIGGER_TYPES);
}

function isCustomEvent(value: string): value is CUSTOM_TRIGGER_TYPES {
  return Object.values(CUSTOM_TRIGGER_TYPES).includes(value as CUSTOM_TRIGGER_TYPES);
}

/**
 * Attach interaction handlers for a given widgetkey
 * Returns -> [widgetHidden: boolean | undefined, widgetSticky: boolean | undefined]
 * Usage -> const [widgetHidden, widgetSticky] = useInteractionHandlers('my-widget');
 */
export const useInteractionEvents = (
  key: string,
  componentKey: string,
  hostRef: React.RefObject<HTMLDivElement>,
): [widgetHidden: boolean | undefined, widgetSticky: boolean | undefined, stickyOffset: number] => {
  const editor = useEditorState();
  const [, dispatch] = useGlobalStateRef();
  const editorState = useGlobalStore((state) => state.editor);
  const { addCallback, removeCallback } = useContext(EventBrokerContext);
  const [widgetHidden, setWidgetHidden] = useState<boolean | undefined>(undefined);
  const [widgetSticky, setWidgetSticky] = useState<boolean | undefined>(undefined);
  const [stickyOffset, setStickyOffset] = useState<number>(0);
  const interactionEvents = useProjectInteractionEvents(key).filter((interaction) =>
    Object.values(GLOBAL_EVENT_TYPES).includes(interaction.event?.type as GLOBAL_EVENT_TYPES),
  );
  const componentInteractions = useProjectInteractionEvents(componentKey).filter((interaction) =>
    Object.values(GLOBAL_EVENT_TYPES).includes(interaction.event?.type as GLOBAL_EVENT_TYPES),
  );
  const animations = useGlobalStore((state) => state.animations);
  const isSelected = editorState?.selection?.includes(key) && !!editorState?.selectedInteraction;

  // Reset hide while in editor and set initial visibility/sticky state
  useEffect(() => {
    if (editor.disabled) {
      setWidgetHidden(undefined);
      setWidgetSticky(undefined);
    }
  }, [editor.disabled]);

  useEffect(() => {
    if (editor.disabled) {
      // Remove all animation classes
      const el = document.getElementById(key + 'c');
      el?.getAnimations().forEach((a) => a.cancel());

      if (isSelected) {
        const interaction = interactionEvents.find(
          (i) => i.key === editorState.selectedInteraction,
        );
        interaction && playAnimation(interaction, animations);
      }

      return;
    }

    function setVisibility(type?: string) {
      switch (type) {
        case GLOBAL_EVENT_TYPES.SHOW:
          return false;
        case GLOBAL_EVENT_TYPES.HIDE:
          return true;
        case GLOBAL_EVENT_TYPES.TOGGLE:
          return hostRef.current?.clientWidth !== 0 && hostRef.current?.clientHeight !== 0;
        default:
          return undefined;
      }
    }

    function setSticky(type?: string) {
      switch (type) {
        case GLOBAL_EVENT_TYPES.STICK:
          return true;
        case GLOBAL_EVENT_TYPES.UNSTICK:
          return false;
        default:
          return undefined;
      }
    }

    const addEventCallback = (interaction: ProjectInteraction, target: string) => {
      addCallback(interaction.event?.type || '', target, (args?: Record<string, string>) => {
        // Handle visibility events
        if (
          interaction.event?.type === GLOBAL_EVENT_TYPES.SHOW ||
          interaction.event?.type === GLOBAL_EVENT_TYPES.HIDE ||
          interaction.event?.type === GLOBAL_EVENT_TYPES.TOGGLE
        ) {
          setWidgetHidden(setVisibility(interaction.event?.type));
        }

        // Handle sticky events
        if (
          interaction.event?.type === GLOBAL_EVENT_TYPES.STICK ||
          interaction.event?.type === GLOBAL_EVENT_TYPES.UNSTICK
        ) {
          const eventArgs = interaction.event?.args as { [attr: string]: any };
          setWidgetSticky(setSticky(interaction.event?.type));
          setStickyOffset(eventArgs?.offset || 0);
        }

        if (VARIANT_EVENTS.includes(interaction.event?.type || '')) {
          const { variant } = (args as { variant: string }) || {};
          const toggle = interaction.event?.type === GLOBAL_EVENT_TYPES.TOGGLE_VARIANT;
          dispatch('change-variant', { key, variant, toggle });
        }

        // Handle animation events
        if (interaction.event?.type === GLOBAL_EVENT_TYPES.ANIMATE) {
          const animationInteraction = interactionEvents.find(
            (i) => i.key === args?.interactionKey,
          );
          animationInteraction && playAnimation(animationInteraction, animations);
        }
      });
    };

    componentInteractions?.forEach(
      (interaction) => addEventCallback(interaction, `${componentKey}-${key}`),
      [componentInteractions, dispatch],
    );

    interactionEvents?.forEach(
      (interaction) => addEventCallback(interaction, key),
      [interactionEvents],
    );

    return () => {
      interactionEvents.forEach((interaction) => {
        if (interaction.event?.type) {
          removeCallback(interaction.event?.type, key);
        }
      });
    };
  }, [
    key,
    hostRef,
    addCallback,
    interactionEvents,
    setWidgetHidden,
    editor.disabled,
    removeCallback,
    widgetHidden,
    widgetSticky,
    stickyOffset,
    componentInteractions,
    componentKey,
    animations,
    isSelected,
    editorState?.selectedInteraction,
  ]);

  return [widgetHidden, widgetSticky, stickyOffset];
};

/**
 * Attach interaction listeners for a given widgetkey
 * Returns -> { onVisible: () => void 'onMouseEnter': () => void}
 * Usage -> const [domListeners] = useInteractionTriggers('my-widget');
 */
export const useInteractionTriggers = (
  key: string,
  componentKey: string,
  hostRef: React.RefObject<HTMLDivElement>,
  defaultTag?: string,
): [
  domListeners: { [key in VevTriggerType]?: (e: MouseEvent) => void },
  widgetNode: string,
  widgetAttrs: {
    [attr: string]: any;
  },
] => {
  const [state] = useGlobalStateRef();
  const interactionTriggers = useProjectInteractionTriggers(key, componentKey);
  const editor = useEditorState();
  const instanceKeyChains: string[] = [];

  if (key.slice(11).length) instanceKeyChains.push(key.slice(11));
  if (componentKey) instanceKeyChains.push(`-${key}`);

  const [nativeTriggers, setNativeTriggers] = React.useState<DOM_TRIGGER_TYPES[]>([]);
  const [customTriggers, setCustomTriggers] = React.useState<CUSTOM_TRIGGER_TYPES[]>([]);

  const firstIntersectionCall = useRef(true);

  // Build array of interaction triggers
  interactionTriggers?.forEach((interaction) => {
    const { type } = interaction.trigger || {};

    // Find all native DOM triggers for this widget
    if (type && isNativeEvent(type)) {
      // Check if event has already been attached
      if (nativeTriggers.includes(type)) return;

      setNativeTriggers((triggers) => [...triggers, type]);
    }

    // Find all custom triggers for this widget
    if (type && isCustomEvent(type)) {
      // Check if event has already been attached
      if (customTriggers.includes(type)) return;

      setCustomTriggers((triggers) => [...triggers, type]);
    }
  });

  const onTrigger = (type: VevTriggerType, args?: any) => {
    const event = { type, contentKey: key.slice(0, 11) } as VevDispatchEvent;
    if (args) event.args = args;
    if (instanceKeyChains) event.instanceKeyChains = instanceKeyChains;
    if (componentKey) event.componentKey = componentKey;
    dispatchVevEvent(event);
  };

  // Attach intersection observer if handlers exist
  useEffect(() => {
    const hasVisibleListener = customTriggers.includes(CUSTOM_TRIGGER_TYPES.ON_VISIBLE);
    const hasLeaveListener = customTriggers.includes(CUSTOM_TRIGGER_TYPES.ON_LEAVE);

    if (hostRef.current && (hasVisibleListener || hasLeaveListener)) {
      return Intersection.add(hostRef.current, (entry) => {
        if (firstIntersectionCall.current && !entry.isIntersecting) {
          firstIntersectionCall.current = false;
        } else {
          if (entry.isIntersecting) onTrigger(CUSTOM_TRIGGER_TYPES.ON_VISIBLE);
          else onTrigger(CUSTOM_TRIGGER_TYPES.ON_LEAVE);
          firstIntersectionCall.current = false;
        }
      });
    }
  }, [customTriggers, editor.disabled]);

  // Attach swipe handlers if handlers wexist
  const hasSwipeListener = customTriggers.includes(CUSTOM_TRIGGER_TYPES.ON_SWIPE);

  useSwipe(hostRef, hasSwipeListener, (direction) => {
    onTrigger(CUSTOM_TRIGGER_TYPES.ON_SWIPE, { direction });
  });

  // Build list of native triggers
  const domListeners = {} as { [key in VevTriggerType]?: (e: MouseEvent) => void };
  for (const trigger of nativeTriggers) {
    domListeners[trigger] = () => onTrigger(trigger);
  }

  const [widgetTag, widgetAttrs] = getInteractionsNodeWithAttributes(
    interactionTriggers,
    state.current,
    defaultTag,
    domListeners,
  );

  return [domListeners, widgetTag, widgetAttrs];
};
