export function attachGlobalListeners() {
  window.addEventListener('keydown', (e) => {
    const customEvent = new CustomEvent('@@vev', {
      detail: {
        type: 'onKeyDown',
        args: {
          key: e.code,
        },
      },
    });
    window.dispatchEvent(customEvent);
  });
  window.addEventListener('keyup', (e) => {
    const customEvent = new CustomEvent('@@vev', {
      detail: {
        type: 'onKeyUp',
        args: {
          key: e.code,
        },
      },
    });
    window.dispatchEvent(customEvent);
  });

  window.addEventListener('scroll', () => {
    const percentage = Math.trunc(
      ((window.scrollY + window.innerHeight) / document.body.scrollHeight) * 100,
    );
    const customEvent = new CustomEvent('@@vev', {
      detail: {
        type: 'onScroll',
        args: {
          scrollTop: window.scrollY,
          scrollHeight: document.body.scrollHeight,
          height: window.innerHeight,
          percentage,
        },
      },
    });
    window.dispatchEvent(customEvent);
  });
}
