import { AnimationArgs, AnimationKeyframesMap, ProjectInteraction } from './types';

export const playAnimation = (
  interaction: ProjectInteraction,
  animations: AnimationKeyframesMap,
  targetId = interaction?.event?.contentKey + 'c',
) => {
  const interactionEvent = interaction?.event;
  const animationArgs = interactionEvent?.args as any as AnimationArgs;
  const el = document.getElementById(targetId as string);
  if (!animationArgs?.animation || !el) return;

  const animation = animations?.[animationArgs?.animation];

  const keyframes = animation?.keyframes.map((keyframe) =>
    replaceVariables(keyframe, animationArgs),
  );

  const animationOptions = createAnimationOptions(
    { ...animationArgs, id: interaction?.key },
    animation?.type,
  );

  el.classList.remove('__hide_animation');
  const exists = el.getAnimations().find((animation) => animation.id === animationOptions.id);
  if (exists) exists.cancel();
  el.animate(keyframes, animationOptions);
};

type AnimationFill = 'in' | 'out' | 'highlight';

function getType(type: AnimationFill) {
  switch (type) {
    case 'in':
      return 'backwards';
    case 'out':
      return 'forwards';
    case 'highlight':
      return undefined;
  }
}
const createAnimationOptions = (
  animationArgs: AnimationArgs & { id: string },
  type: AnimationFill,
): KeyframeAnimationOptions => {
  const createScrollTimeline = (animationArgs: AnimationArgs) => {
    const timeline = new (window as any).ScrollTimeline({
      source: document.documentElement,
      axis: 'block',
    });
    return {
      timeline,
      rangeStart: `entry ${animationArgs['animation-range-start'] || 0}%`,
      rangeEnd: `entry ${animationArgs['animation-range-end'] || 100}%`,
    };
  };

  return {
    id: animationArgs.id,
    duration: +animationArgs?.duration * 1000 || 1000,
    delay: +animationArgs?.delay * 1000 || 0,
    easing: animationArgs?.easing || 'ease',
    fill: getType(type),
    iterations: animationArgs?.loop ? Infinity : 1,
    direction: animationArgs?.alternate ? 'alternate' : 'normal',
    ...(animationArgs.scroll ? createScrollTimeline(animationArgs) : {}),
  };
};

const replaceVariables = (keyframe: Keyframe, animationArgs: AnimationArgs) => {
  const variables = Object.keys(animationArgs || {}).filter((key) => key.startsWith('--'));

  let obj = JSON.stringify(keyframe);
  while (obj.includes('var(')) {
    for (const variable of variables) {
      obj = obj.replace(new RegExp(`var\\(${variable}\\)`, 'g'), animationArgs[variable as any]);
    }
  }
  return JSON.parse(obj);
};
